<template>
  <div class="account-change-password">
    <div class="card p-2">
      <fd-form @submit.prevent="updatePassword">
        <h4 class="mainTitle mb-4">Change Account Password</h4>
        <div class="main-contents">
          <fd-input
            class="mb-2"
            :type="showPassword ? 'text' : 'password'"
            :label="`New Password`"
            v-model="account.password"
            :validators="[validators.required, validators.password]"
            validationTooltip
          />
          <fd-input
            class="mb-2"
            :type="showPassword ? 'text' : 'password'"
            :label="`Retype Password`"
            v-model="account.retypePassword"
            :validators="[validators.required, validators.password]"
            validationTooltip
          />
          <fd-checkbox
            v-model="showPassword"
            label="Show Password"
          ></fd-checkbox>
        </div>
        <div class="mt-4 text-right">
          <button type="submit" class="btn main">Update</button>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  password
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      showPassword: false,
      account: {
        password: "",
        retypePassword: ""
      },

      validators: {
        required: required,
        password: password
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    validateError() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Validation failed, please check if all the inputs are valid."
      });
    },
    clearInputs() {
      this.account.password = "";
      this.account.retypePassword = "";
      this.showPassword = false;
    },
    updatePassword() {
      if (this.account.password != this.account.retypePassword) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Password and confirmation password does not match."
        });
        return;
      } else {
        this.$emit("update", this.account.password);
      }
    }
  }
};
</script>

<style lang="scss">
.account-change-password {
}
</style>
